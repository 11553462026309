class ZIDXAccountDataAllFeedStatus implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDataAllFeedStatusContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/allFeedStatus";
    }
    render(){
        // console.log("data feed status");
        let self=this;
        setTimeout(function(){
            window.location.reload();
        }, 10000);
    }
}